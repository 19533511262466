export const TWOLEVEL: any = {
    "id": 237019,
    "directionmodeid": 1,
    "name": "Экзамен на месте 1",
    "takerfio": "Петрова Анна Александровна",
    "autorid": 4183,
    "description": "--",
    "questions": [
        {
            "id": 7576,
            "name": "Обязаны ли работники, обеспечивающие охрану образовательных организаций, проходить обязательные медицинские осмотры?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85017,
                    "questionId": 7576,
                    "name": "Не обязаны, поскольку это не предусмотрено трудовым договором",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85018,
                    "questionId": 7576,
                    "name": "Обязаны в соответствии с ч. 9 статьи 12 Закона РФ от 11 марта 1992 г. № 2487-1 «О частной детективной и охранной деятельности в Российской Федерации»",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85019,
                    "questionId": 7576,
                    "name": "Обязаны, в случае наличия соответствующего пункта в контракте (договоре) на оказание охранных услуг",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7579,
            "name": "Какие работы, не связанные с охраной объекта, может выполнять работник стационарного поста охраны в образовательной организации во время дежурства?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85026,
                    "questionId": 7579,
                    "name": "Никакие",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85027,
                    "questionId": 7579,
                    "name": "Работы по просьбе администрации образовательной организации, касающиеся безопасности детей (скол льда, сбивание сосулек с крыши, очистка от снега подъездных путей к эвакуационным выходам)",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85028,
                    "questionId": 7579,
                    "name": "Работы, связанные с оказанием помощи аварийным бригадам при техногенных авариях по минимизации материального ущерба",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7583,
            "name": "Кому из состава охраны образовательной организации разрешено применение служебного оружия и специальных средств на территории охраняемого объекта?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85038,
                    "questionId": 7583,
                    "name": "Никому",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85039,
                    "questionId": 7583,
                    "name": "Работнику по обеспечению охраны в образовательной организации при наличии действительного (не старше 12 месяцев) акта о прохождении периодической проверки",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85040,
                    "questionId": 7583,
                    "name": "Только экипажу мобильной группы частной охранной организации",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7575,
            "name": "Допускается ли причинение вреда третьим лицам в состоянии необходимой обороны?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85014,
                    "questionId": 7575,
                    "name": "Да, при вооруженном нападении",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85015,
                    "questionId": 7575,
                    "name": "Да, при групповом нападении",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85016,
                    "questionId": 7575,
                    "name": "Нет",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7573,
            "name": "Правовым основанием для соблюдения работниками, обеспечивающими охрану образовательных организаций, правил и норм пожарной безопасности и прохождения ими обязательного противопожарного инструктажа является:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85008,
                    "questionId": 7573,
                    "name": "Постановление Правительства РФ от 16 сентября 2020 г. № 1479 «Об утверждении правил противопожарного режима в Российской Федерации», приказ МЧС России от 12 декабря 2007 г. № 645 «Об утверждении норм пожарной безопасности «Обучение мерам пожарной безопасности работников организаций»",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85009,
                    "questionId": 7573,
                    "name": "Федеральный закон от 11 марта 1992 г. № 2487-1 «О частной детективной и охранной деятельности в Российской Федерации»",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85010,
                    "questionId": 7573,
                    "name": "Федеральный закон от 29 декабря 2012 г. № 273-ФЗ «Об образовании в Российской Федерации»",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7585,
            "name": "Будет ли отвечать всем условиям закона задержание частным охранником лица, нарушающего внутриобъектовый и (или) пропускной режимы, в случае если в действиях по нарушению указанных режимов нет состава административного правонарушения или преступления (либо отсутствуют иные основания, по которым полиция имеет право на доставление в орган внутренних дел)?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85044,
                    "questionId": 7585,
                    "name": "Будет",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85045,
                    "questionId": 7585,
                    "name": "Будет, при условии, что задержание такого лица предусмотрено положением о внутриобъектовом и (или) пропускном режиме на объекте",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85046,
                    "questionId": 7585,
                    "name": "Не будет, поскольку в отсутствие оснований для доставления в полицию орган внутренних дел не примет задержанного от частного охранника (а его незамедлительная передача в орган внутренних дел – обязательное условие задержания)",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7574,
            "name": "Могут ли действия охранника по защите жизни и здоровья другого лица расцениваться как действия в состоянии необходимой обороны:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85011,
                    "questionId": 7574,
                    "name": "Могут, если соблюдены условия необходимой обороны, предусмотренные законом",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85012,
                    "questionId": 7574,
                    "name": "Могут, только если при указанном лице находилось охраняемое имущество",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85013,
                    "questionId": 7574,
                    "name": "Не могут ни при каких условиях",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7578,
            "name": "Для работника, обеспечивающего охрану образовательной организации, обязательными требованиями при продлении удостоверения частного охранника являются:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85023,
                    "questionId": 7578,
                    "name": "Требования об усвоении программы профессионального обучения для работы в качестве частного охранника - «Программа повышения квалификации охранника»",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85024,
                    "questionId": 7578,
                    "name": "Требования предоставить акт о прохождении периодической проверки, если со дня ее прохождения прошло не более 11 месяцев",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85025,
                    "questionId": 7578,
                    "name": "Требования, предъявляемые профессиональным стандартом «Работник по обеспечению охраны образовательных организаций» об усвоении программы профессиональной переподготовки",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7581,
            "name": "Каким федеральным законом или иным нормативным правовым актом запрещается употребление алкогольной продукции на территории и в помещениях образовательной организации?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85032,
                    "questionId": 7581,
                    "name": "Положением о внутриобъектовом и пропускном режимах в образовательной организации",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85033,
                    "questionId": 7581,
                    "name": "Федеральный закон от 22 ноября 1995 г. № 171-ФЗ «О государственном регулировании производства и оборота этилового спирта, алкогольной и спиртосодержащей продукции и об ограничении потребления (распития) алкогольной продукции»",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85034,
                    "questionId": 7581,
                    "name": "Федеральный закон от 29 декабря 2012 г. № 273-ФЗ «Об образовании в Российской Федерации» (п.7. статьи 41)",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7588,
            "name": "Каким Федеральным законом или иным нормативным правовым актом запрещается курение табака на территориях и в помещениях, предназначенных для оказания образовательных услуг?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85053,
                    "questionId": 7588,
                    "name": "Приказом Минздравсоцразвития России от 12 мая 2014 г. №214н «Об утверждении требований к знаку о запрете курения и к порядку его размещения»",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85054,
                    "questionId": 7588,
                    "name": "Федеральным законом от 23 февраля 2013 г. № 15-ФЗ «Об охране здоровья граждан от воздействия табачного дыма и последствий потребления табака»",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85055,
                    "questionId": 7588,
                    "name": "Федеральным законом от 29 декабря 2012 г. № 273-ФЗ «Об образовании в Российской Федерации»",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7572,
            "name": "Частные охранники имеют право применять физическую силу:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85005,
                    "questionId": 7572,
                    "name": "В случаях, если Законом РФ «О частной детективной и охранной деятельности в РФ» им разрешено применение специальных средств или огнестрельного оружия",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85006,
                    "questionId": 7572,
                    "name": "Только в случаях, если Законом РФ «О частной детективной и охранной деятельности в РФ» им разрешено применение огнестрельного оружия",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85007,
                    "questionId": 7572,
                    "name": "Только в случаях, если Законом РФ «О частной детективной и охранной деятельности в РФ» им разрешено применение специальных средств",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7580,
            "name": "В каких случаях частному охраннику не запрещается применять специальные средства в отношении женщин с видимыми признаками беременности, лиц с явными признаками инвалидности и несовершеннолетних, возраст которых охраннику очевиден или известен?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85029,
                    "questionId": 7580,
                    "name": "В случае оказания ими вооруженного сопротивления, совершения группового либо иного нападения, угрожающего жизни и здоровью частного охранника или охраняемому имуществу",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85030,
                    "questionId": 7580,
                    "name": "В случае отказа нарушителя подчиниться требованию охранника проследовать в помещение охраны",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85031,
                    "questionId": 7580,
                    "name": "В случаях оказания указанными лицами группового сопротивления",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7582,
            "name": "В каких случаях, согласно Закону РФ от 11 марта 1992 г. № 2487-1 «О частной детективной и охранной деятельности в Российской Федерации», охраннику разрешается применять огнестрельное оружие в отношении несовершеннолетних, когда их возраст очевиден или известен охраннику?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85035,
                    "questionId": 7582,
                    "name": "В случае бегства несовершеннолетних с места происшествия, когда иными способами остановить их не представляется возможным",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85036,
                    "questionId": 7582,
                    "name": "В случае отказа выполнить требование лечь на пол лицом вниз и положить руки за голову",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85037,
                    "questionId": 7582,
                    "name": "В случаях оказания несовершеннолетними вооруженного сопротивления, совершения вооруженного или группового нападения, угрожающего жизни охранника или охраняемому имуществу",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7570,
            "name": "Осмотр сотрудником стационарного поста охраны в образовательной организации с установленным пропускным режимом въезжающих/выезжающих транспортных средств в случае возникновения подозрения, что указанные транспортные средства используются в противоправных целях, а также осмотр вносимого/выносимого имущества должен производиться в присутствии:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 84999,
                    "questionId": 7570,
                    "name": "Водителей указанных транспортных средств и лиц, сопровождающих указанные транспортные средства и имущество",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85000,
                    "questionId": 7570,
                    "name": "Дежурного администратора образовательной организации",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85001,
                    "questionId": 7570,
                    "name": "Приглашенных в качестве понятых посторонних граждан или с использованием видео/фото съемки",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7589,
            "name": "На основании каких нормативных правовых актов возможна эвакуация автотранспортного средства, оставленного водителем накануне в непосредственной близости от периметра образовательной организации, если запрещающие стоянку знаки и специальная разметка отсутствуют?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85056,
                    "questionId": 7589,
                    "name": "На основании п.п. «д» п. 20 Постановления Правительства РФ от 02 августа 2019 г. № 1006 «Об утверждении требований к антитеррористической защищенности объектов (территорий) Министерства просвещения Российской Федерации и объектов (территорий), относящихся к сфере деятельности Министерства просвещения Российской Федерации, и формы паспорта безопасности этих объектов (территорий)»",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85057,
                    "questionId": 7589,
                    "name": "На основании Постановления Правительства РФ от 06 июня 2007 г. № 352 «О мерах по реализации Федерального закона «О противодействии терроризму»",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85058,
                    "questionId": 7589,
                    "name": "На основании Федерального закона от 06 марта 2006 г. № 35-ФЗ «О противодействии терроризму»",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7591,
            "name": "В соответствии с Постановлением Правительства РФ от 02.08.2019 № 1006 «Об утверждении требований к антитеррористической защищенности объектов (территорий) Министерства просвещения Российской Федерации и объектов (территорий), относящихся к сфере деятельности Министерства просвещения Российской Федерации, и формы паспорта безопасности этих объектов (территорий)» ответственность за обеспечение антитеррористической защищенности объектов (территорий) возлагается на:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85062,
                    "questionId": 7591,
                    "name": "Министерство внутренних дел",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85063,
                    "questionId": 7591,
                    "name": "Правительство Российской Федерации",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85064,
                    "questionId": 7591,
                    "name": "Руководителей органов (организаций), являющихся правообладателями объектов (территорий), а также на должностных лиц, осуществляющих непосредственное руководство деятельностью работников на объектах (территориях)",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7593,
            "name": "Прохождение химико-токсикологического исследования на наличие в организме человека наркотических средств, психотропных веществ и их метаболитов при прохождении работниками охраны образовательных организаций ежегодного медицинского обследования:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85068,
                    "questionId": 7593,
                    "name": "Не является обязательным",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85069,
                    "questionId": 7593,
                    "name": "Является обязательным",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85070,
                    "questionId": 7593,
                    "name": "Является обязательным, если это оговорено в трудовом договоре охранника с частной охранной организацией",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7571,
            "name": "На кого в соответствии с Федеральным законом от 29 декабря 2012 г. № 273-ФЗ «Об образовании в Российской Федерации», возложена персональная ответственность за организацию охраны здоровья обучающихся (воспитанников) во время их пребывания в образовательной организации?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85002,
                    "questionId": 7571,
                    "name": "На генерального директора частной охранной организации, с которой заключен контракт (договор) на осуществление комплекса мер, направленных на защиту материального имущества объектов, обеспечение внутриобъектового и пропускного режимов",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85003,
                    "questionId": 7571,
                    "name": "На начальника охраны (объекта, участка), ответственного за организацию охраны согласно контракту (договору)",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85004,
                    "questionId": 7571,
                    "name": "На руководителя образовательной организации",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7590,
            "name": "В соответствии со статьей 108 Трудового кодекса РФ работнику стационарного поста охраны в образовательной организации при суточном графике дежурства предоставляется время для приема пищи:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85059,
                    "questionId": 7590,
                    "name": "не менее 1 часа, но не более 2 часов",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85060,
                    "questionId": 7590,
                    "name": "не менее 1 часа, но не более 3 часов",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85061,
                    "questionId": 7590,
                    "name": "не менее 30 минут, но не более 2 часов",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7569,
            "name": "Какие меры принуждения могут применять частные охранники?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 84996,
                    "questionId": 7569,
                    "name": "Задержание на месте правонарушения лиц, совершивших противоправное посягательство на охраняемое имущество либо нарушающих внутриобъектовый и (или) пропускной режимы, применение физической силы, специальных средств и огнестрельного оружия, разрешенных в частной охранной деятельности",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 84997,
                    "questionId": 7569,
                    "name": "Изъятие предметов, досмотр транспорта, применение огнестрельного и холодного оружия",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 84998,
                    "questionId": 7569,
                    "name": "Проверка документов, досмотр переносимых вещей, применение физической силы, специальных средств и огнестрельного оружия, разрешенных в частной охранной деятельности",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7584,
            "name": "Какой нормативный документ определяет право охранника образовательной организации не допускать на охраняемый объект лиц, не предъявивших установленные документы?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85041,
                    "questionId": 7584,
                    "name": "Должностная инструкция частного охранника, составленная в соответствии с Положением об организации внутриобъектового и пропускного режимов на объекте образования",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85042,
                    "questionId": 7584,
                    "name": "Статья 20.17. КоАП РФ «Нарушение пропускного режима охраняемого объекта»",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85043,
                    "questionId": 7584,
                    "name": "Федеральный закон от 29 декабря 2012 г. № 273-ФЗ «Об образовании в Российской Федерации»",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7592,
            "name": "Работник стационарного поста охраны в образовательной организации допускает сотрудников комбината питания на пищеблок образовательной организации на основании:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85065,
                    "questionId": 7592,
                    "name": "Списка сотрудников комбината питания с резолюцией руководителя образовательной организации (или соответствующего приказа) при предъявлении паспорта",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85066,
                    "questionId": 7592,
                    "name": "Устного разрешения дежурного администратора образовательной организации при предъявлении любого документа, удостоверяющего личность",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85067,
                    "questionId": 7592,
                    "name": "Устного распоряжения руководителя образовательной организации при предъявлении паспорта или служебного удостоверения",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7586,
            "name": "Руководитель коммерческой образовательной организации потребовал у работника стационарного поста охраны оформить разрешение на покупку и использование огнестрельного оружия ограниченного поражения с тем, чтобы иметь его при себе на посту охраны. Что необходимо предпринять работнику охраны?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85047,
                    "questionId": 7586,
                    "name": "Объяснить руководителю образовательной организации, что данное требование выполнимо только при наличии разрешения руководителя частной охранной организации",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85048,
                    "questionId": 7586,
                    "name": "Объяснить руководителю образовательной организации, что данное требование невыполнимо, поскольку в соответствии со ст. 6 Федерального закона от 13 декабря 1996 г. № 150-ФЗ «Об оружии» гражданам запрещено ношение огнестрельного оружия ограниченного поражения на территории образовательной организации",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85049,
                    "questionId": 7586,
                    "name": "Удовлетворить требование руководителя образовательной организации, чтобы не создавать конфликтную ситуацию",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7577,
            "name": "Превышение мер, необходимых для задержания лица, совершившего преступление (их явное несоответствие характеру и степени общественной опасности совершенного задерживаемым лицом преступления и обстоятельствам задержания), влечет за собой уголовную ответственность:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85020,
                    "questionId": 7577,
                    "name": "Во всех случаях причинения вреда здоровью задерживаемого (независимо от наличия или отсутствия умысла)",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85021,
                    "questionId": 7577,
                    "name": "Только в случаях умышленного причинения смерти, тяжкого или средней тяжести вреда здоровью задерживаемого",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85022,
                    "questionId": 7577,
                    "name": "Только в случаях умышленного причинения смерти, тяжкого, средней тяжести или легкого вреда здоровью задерживаемого",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7587,
            "name": "В какие периоды в течение смены работник стационарного поста охраны в образовательной организации носит согласованную форму одежды (форменное обмундирование)?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85050,
                    "questionId": 7587,
                    "name": "Носит в течение всей смены, не снимая",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85051,
                    "questionId": 7587,
                    "name": "Снимает в бытовом помещении на период сна",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85052,
                    "questionId": 7587,
                    "name": "Снимает после ухода из образовательной организации всех участников образовательного процесса",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7612,
            "name": "Какое из перечисленных условий задержания, осуществляемого охранниками, является тактическим:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85125,
                    "questionId": 7612,
                    "name": "Необходимость незамедлительной передачи задерживаемых в органы внутренних дел",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85126,
                    "questionId": 7612,
                    "name": "Необходимость удержания инициативы в ходе задержания",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85127,
                    "questionId": 7612,
                    "name": "Необходимость учета правового иммунитета к задержанию определенных категорий лиц",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7598,
            "name": "Какие действия должен предпринять работник стационарного поста охраны при выходе из строя электрозамка на калитке дошкольной образовательной организации?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85083,
                    "questionId": 7598,
                    "name": "Доложить оперативному дежурному частной охранной организации и начальнику охраны, после чего находиться возле калитки, регулируя проход на территорию образовательной организации механическим замком",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85084,
                    "questionId": 7598,
                    "name": "Доложить оперативному дежурному частной охранной организации и потребовать вызвать работника охраны из группы резерва для выставления дополнительного поста у калитки на весь период до проведения ремонта",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85085,
                    "questionId": 7598,
                    "name": "Оперативного дежурного частной охранной организации не беспокоить, потребовать у руководителя дошкольной образовательной организации выставить у калитки одного из сотрудников образовательной организации, так как это обычный рабочий момент, а не чрезвычайная ситуация",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7594,
            "name": "Периодичность проведения работником стационарного поста охраны в образовательной организации обходов территории и осмотров состояния здания, строений, сооружений и ограждения периметра образовательной организации составляет:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85071,
                    "questionId": 7594,
                    "name": "В соответствии с Положением (инструкцией) об организации внутриобъектового и пропускного режимов на объекте образования, но не менее трех раз в сутки",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85072,
                    "questionId": 7594,
                    "name": "Один раз в утренние часы (до начала массового прихода в образовательную организацию обучающихся и воспитанников), а затем каждые два часа",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85073,
                    "questionId": 7594,
                    "name": "После принятия объекта под охрану каждые два часа в течение суточной смены",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7604,
            "name": "Первоочередные действия работника стационарного поста охраны в образовательной организации в случае срабатывания на охраняемом объекте взрывного устройства:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85101,
                    "questionId": 7604,
                    "name": "Зафиксировать время взрыва, немедленно открыть полный доступ, обеспечив тем самым беспрепятственную эвакуацию обучающихся, персонала и посетителей образовательной организации на безопасное удаление, проинформировать правоохранительные органы, оказать первую доврачебную помощь пострадавшим",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85102,
                    "questionId": 7604,
                    "name": "Незамедлительно сообщить руководителю образовательной организации, покинуть образовательную организацию, взяв личные вещи и документы, до прибытия экстренных служб находиться на безопасном расстоянии",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85103,
                    "questionId": 7604,
                    "name": "Отключить на объекте электричество и газоснабжение, проинформировать сотрудников полиции, осуществить меры по ликвидации последствий, оказать помощь пострадавшим",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7613,
            "name": "Дополнительным тактическим действием при задержании, осуществляемом охранниками, может быть:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85128,
                    "questionId": 7613,
                    "name": "Использование служебных собак",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85129,
                    "questionId": 7613,
                    "name": "Наличие у охраны оружия и специальных средств",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85130,
                    "questionId": 7613,
                    "name": "Подача сигналов свистком, принятых в органах внутренних дел",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7601,
            "name": "В часы утреннего прохода обучающихся в здание образовательной организации работник стационарного поста охраны услышал характерный звук от соприкосновения бутылок, предположительно находящихся в рюкзаке учащегося старших классов. Какие действия он должен предпринять?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85092,
                    "questionId": 7601,
                    "name": "Отказать обучающемуся в проходе в здание образовательной организации",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85093,
                    "questionId": 7601,
                    "name": "Предложить школьнику показать содержимое рюкзака; при наличии в рюкзаке запрещенных к проносу предметов пригласить дежурного администратора образовательной организации для принятия к нарушителю соответствующих мер",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85094,
                    "questionId": 7601,
                    "name": "Составить акт о нарушении пропускного режима",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7614,
            "name": "Эффективные тактические действия охранников по обеспечению безопасности охраняемого объекта предполагают:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85131,
                    "questionId": 7614,
                    "name": "Ликвидация угроз безопасности объекта по мере их возникновения (в рамках полномочий и тактических возможностей охранников)",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85132,
                    "questionId": 7614,
                    "name": "Обнаружение, а затем - пресечение угроз безопасности объекта (в рамках полномочий и тактических возможностей охранников)",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85133,
                    "questionId": 7614,
                    "name": "Предупреждение, обнаружение, а затем - пресечение угроз безопасности объекта (в рамках полномочий и тактических возможностей охранников)",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7599,
            "name": "Какие действия должен предпринять работник стационарного поста охраны в образовательной организации при поступлении звонка о заложенной бомбе?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85086,
                    "questionId": 7599,
                    "name": "Незамедлительно проинформировать руководство образовательной организации, оперативного дежурного частной охранной организации и правоохранительные органы. Совместно с руководством образовательной организации обеспечить организованную эвакуацию обучающихся и персонала по безопасному маршруту. Оцепить зону возможного поражения",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85087,
                    "questionId": 7599,
                    "name": "По средствам громкой связи объявить о заложенной в образовательной организации бомбе, после чего вместе с обучающимися и сотрудниками покинуть объект",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85088,
                    "questionId": 7599,
                    "name": "Проинформировать руководство объекта образования и незамедлительно принять меры по обнаружению и обезвреживанию взрывного устройства",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7606,
            "name": "Первоначальные действия охранника при обнаружении предмета с признаками взрывного устройства:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85107,
                    "questionId": 7606,
                    "name": "Действовать по указанию администрации охраняемого объекта",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85108,
                    "questionId": 7606,
                    "name": "Зафиксировать время обнаружения, принять меры к ограждению и охране подходов к опасной зоне, проинформировать правоохранительные органы",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85109,
                    "questionId": 7606,
                    "name": "Осмотреть подозрительный предмет и перенести его в безопасное место, проинформировать правоохранительные органы",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7607,
            "name": "Какие действия не входят в примерную общую последовательность действий на месте происшествия?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85110,
                    "questionId": 7607,
                    "name": "Организация оцепления (ограждения) места происшествия, а также принятие иных мер к сохранению обстановки и следов на месте происшествия (фотографирование места происшествия и т.п.); регистрация данных прибывающих экстренных служб (государственные или бортовые номера а/машин и т.п.); доклад обстановки старшему следственно-оперативной группы; продолжение выполнения задач по охране объекта",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85111,
                    "questionId": 7607,
                    "name": "Прохождение сотрудниками охраны инструктажа о порядке действий на месте происшествия",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85112,
                    "questionId": 7607,
                    "name": "Фиксация точного времени происшествия; вызов на место происшествия сотрудников территориального органа внутренних дел и иных экстренных служб; доклад о происшедшем событии с службу охраны, администрацию объекта; оказание первой помощи пострадавшим; организация эвакуации людей, предотвращение развития пожаров и иных опасных последствий происшествия; организация усиления охраны; регистрация данных очевидцев происшествия",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7596,
            "name": "Работник стационарного поста охраны в образовательной организации по прибытии на объект образования аварийных служб и в период их работы обязан:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85077,
                    "questionId": 7596,
                    "name": "Встретить аварийные службы, о каждом этапе их работ докладывать по телефону оперативному дежурному частной охранной организации",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85078,
                    "questionId": 7596,
                    "name": "Организовать встречу аварийных служб, вызвать по телефону должностных лиц образовательной организации для контроля за действиями аварийных бригад",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85079,
                    "questionId": 7596,
                    "name": "Организовать встречу и допуск аварийных служб на территорию образовательной организации, показать им признаки аварии и аварийный участок, стараться контролировать их действия при проведении работ. О ликвидации аварийной ситуации доложить оперативному дежурному частной охранной организации и руководству образовательной организации",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7603,
            "name": "Порядок действий работника стационарного поста охраны в образовательной организации при обнаружении надписи экстремистского содержания на стене здания объекта образования:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85098,
                    "questionId": 7603,
                    "name": "Доложить оперативному дежурному частной охранной организации и далее действовать по его указанию",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85099,
                    "questionId": 7603,
                    "name": "Немедленно доложить руководителю образовательной организации, по возможности сфотографировать надпись и сделать запись в журнале",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85100,
                    "questionId": 7603,
                    "name": "Никому не докладывать, сделать запись в журнале для последующего доклада при смене дежурства",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7602,
            "name": "Что должен предпринять работник стационарного поста охраны в дошкольной образовательной организации, если во время планового обхода территории он обнаружил лаз под ограждением периметра, в который могут пролезть дети:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85095,
                    "questionId": 7602,
                    "name": "Доложить оперативному дежурному частной охранной организации и по возможности заградить лаз подручными средствами",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85096,
                    "questionId": 7602,
                    "name": "Доложить руководителю образовательной организации",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85097,
                    "questionId": 7602,
                    "name": "Никому не докладывать, сделать запись в журнале для последующего доклада при смене дежурства",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7615,
            "name": "Какой вариант состава и периодичности производства доклада о наличии либо отсутствии признаков террористической угрозы на охраняемом объекте в наибольшей степени соответствует задаче обеспечения безопасности?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85134,
                    "questionId": 7615,
                    "name": "Доклад о наличии либо отсутствии признаков террористической угрозы на охраняемом объекте производится при заступлении на пост и при сдаче поста; включает в себя информацию о том, что осмотрено непосредственно место расположения охранника (центр поста), признаки наличия возможной террористической угрозы (обнаружены или не обнаружены)",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85135,
                    "questionId": 7615,
                    "name": "Доклад о наличии либо отсутствии признаков террористической угрозы на охраняемом объекте производится при заступлении на пост, при сдаче поста, а также в течение смены (с установленной руководством периодичностью); включает в себя информацию о том, что осмотрена зона ответственности охранника и участки территории, непосредственно к ней прилегающие, в том числе критические элементы, технические полости, коммуникации, предметы и оборудование в помещениях,  в результате чего посторонние предметы, предметы, принадлежность и назначение которых неизвестны, посторонние лица, лица с подозрительным или нестандартным поведением, а также иные признаки наличия возможной террористической угрозы (обнаружены или не обнаружены)",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85136,
                    "questionId": 7615,
                    "name": "Доклад о наличии либо отсутствии признаков террористической угрозы на охраняемом объекте производится только при заступлении на пост; включает в себя информацию о том, что осмотрено непосредственно место расположения охранника (центр поста), признаки наличия возможной террористической угрозы (обнаружены или не обнаружены)",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7616,
            "name": "Какие из перечисленных ниже действий охранника наиболее целесообразны в целях предотвращения угрозы персоналу и посетителям при обнаружении на объекте предмета с признаками взрывного устройства?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85137,
                    "questionId": 7616,
                    "name": "Вызвать специально подготовленных охранников из числа назначенных приказом руководителя частной охранной организации для перемещения подозрительного предмета в безопасное для персонала и посетителей место",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85138,
                    "questionId": 7616,
                    "name": "Не допускать прикосновения к подозрительному предмету каких-либо лиц, кроме уполномоченных сотрудников правоохранительных органов и МЧС России",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85139,
                    "questionId": 7616,
                    "name": "Незамедлительно лично переместить подозрительный предмет в безопасное для персонала и посетителей место",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7610,
            "name": "Какой вариант ответа более полно отражает необходимые действия в ходе осмотра объекта или части объекта (зоны ответственности охранника) при заступлении на пост в целях обнаружения признаков наличия возможной террористической угрозы?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85119,
                    "questionId": 7610,
                    "name": "Осмотр коммуникаций и критических элементов объекта (или части объекта, входящей в зону ответственности охранника), технических полостей, предметов и оборудования в помещениях с уточнением наличия угрожающих признаков, принадлежности и назначения обнаруживаемых предметов",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85120,
                    "questionId": 7610,
                    "name": "Осмотр предметов и оборудования в помещениях с уточнением наличия угрожающих признаков, принадлежности и назначения обнаруживаемых предметов",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85121,
                    "questionId": 7610,
                    "name": "Осмотр прилегающей территории и периметра объекта (либо их участков, непосредственно прилегающих к зоне ответственности охранника), осмотр коммуникаций и критических элементов объекта (или части объекта, входящей в зону ответственности охранника), технических полостей, предметов и оборудования в помещениях с уточнением наличия угрожающих признаков, принадлежности и назначения обнаруживаемых предметов",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7597,
            "name": "Работник, обеспечивающий охрану образовательной организации на стационарном посту, во время обхода территории увидел коробку с торчащими проводами, лежащую возле забора. Что необходимо предпринять работнику охраны?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85080,
                    "questionId": 7597,
                    "name": "Аккуратно вынести коробку за ограждение территории образовательной организации и вызвать кинологов со служебной собакой для обследования содержимого коробки",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85081,
                    "questionId": 7597,
                    "name": "Нажать кнопку экстренного вызова полиции, зафиксировать время обнаружения, взять у дежурного администратора сигнальную ленту и оградить ей опасную зону, убедиться в отсутствии по близости детей, персонала и посетителей образовательной организации. До прибытия сотрудников полиции охранять подходы к опасной зоне. Доложить о происшествии оперативному дежурному частной охранной организации и начальнику охраны, сделать запись в журнале",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85082,
                    "questionId": 7597,
                    "name": "Убедиться, что рядом нет детей, подойти к коробке, открыть ее. Если в ней находится подозрительный предмет, по внешнему виду напоминающий взрывное устройство, незамедлительно вызвать сотрудников полиции",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7595,
            "name": "Работник стационарного поста охраны в образовательной организации при необходимости кратковременного (до 30 минут) оставления поста имеет право передать ключи от входной двери:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85074,
                    "questionId": 7595,
                    "name": "Дежурному администратору образовательной организации",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85075,
                    "questionId": 7595,
                    "name": "Никому",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85076,
                    "questionId": 7595,
                    "name": "Ответственному за безопасность в образовательной организации",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7608,
            "name": "Оптимальными действиями охранника по прекращению агрессии толпы в отношении объекта охраны являются:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85113,
                    "questionId": 7608,
                    "name": "Вступление сотрудников охраны в физическое противоборство с толпой",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85114,
                    "questionId": 7608,
                    "name": "Переключение внимания толпы; выделение в толпе лидеров и переговоры с ними с целью снижения агрессии",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85115,
                    "questionId": 7608,
                    "name": "Применение специальных средств или оружия на поражение",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7600,
            "name": "Где должен находиться работник охраны дошкольной образовательной организации во время прогулки детей на территории?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85089,
                    "questionId": 7600,
                    "name": "На рабочем месте за монитором, непрерывно осуществляя видеоконтроль за всей территорией, на которой осуществляется прогулка",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85090,
                    "questionId": 7600,
                    "name": "На территории, стараясь держаться между ограждением периметра образовательной организации и детьми, и по возможности удерживать в поле зрения как можно большую площадь, обращая внимание на граждан, находящихся в непосредственной близости к ограждению",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85091,
                    "questionId": 7600,
                    "name": "Патрулировать за периметром образовательной организации для своевременного обнаружения злоумышленников и пресечения их действий",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7609,
            "name": "Процедура заступления охранника на пост по охране стационарного объекта начинается:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85116,
                    "questionId": 7609,
                    "name": "С внесения записи в журнал приема-сдачи дежурств",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85117,
                    "questionId": 7609,
                    "name": "С доклада администрации охраняемого объекта о заступлении на дежурство",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85118,
                    "questionId": 7609,
                    "name": "С осмотра объекта и прилегающей территории",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7618,
            "name": "Какое отличие имеется в последовательности действий при обнаружении предметов, предположительно содержащих отравляющие вещества (ОВ), по сравнению с действиями при обнаружении взрывчатых веществ (ВВ) и взрывных устройств (ВУ):",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85143,
                    "questionId": 7618,
                    "name": "Одно из первых действий – обильно залить обнаруженный предмет пеной из воздушно-пенного или углекислотного огнетушителя",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85144,
                    "questionId": 7618,
                    "name": "Одно из первых действий - открыть окна помещения, в котором обнаружен подозрительный предмет",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85145,
                    "questionId": 7618,
                    "name": "Одно из первых действий - приготовить и надеть средства индивидуальной защиты (противогазы или защитные капюшоны, резиновые перчатки и т.п.)",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7617,
            "name": "При нахождении на посту по охране стационарного объекта охранник заметил драку, происходящую в непосредственной близости к объекту. Какой из вариантов действий охранника на объекте наиболее правилен:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85140,
                    "questionId": 7617,
                    "name": "Выйти и разнять дерущихся граждан, так как могут быть повреждены ограждающие конструкции охраняемого объекта",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85141,
                    "questionId": 7617,
                    "name": "Не открывая дверей объекта, сообщить в органы внутренних дел",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85142,
                    "questionId": 7617,
                    "name": "Не предпринимать никаких действий, так как правонарушение происходит вне пределов охраняемого объекта",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7611,
            "name": "Какие признаки, применяемые при составлении словесного портрета, позволяют наиболее быстро и достоверно выделить описываемое лицо в толпе?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85122,
                    "questionId": 7611,
                    "name": "Анатомические признаки (описание головы, лица, волос, иных частей тела)",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85123,
                    "questionId": 7611,
                    "name": "Сопутствующие элементы и признаки (одежда, украшения, используемые предметы)",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85124,
                    "questionId": 7611,
                    "name": "Функциональные признаки (осанка, походка, жестикуляция, мимика, голос и т.п.)",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7605,
            "name": "Действия работника стационарного поста охраны в образовательной организации при поступлении сигнала в ночное время, выходные и праздничные дни от системы охранной сигнализации о вскрытии помещения, где хранятся компьютеры:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85104,
                    "questionId": 7605,
                    "name": "Нажать кнопку экстренного вызова полиции, попытаться задержать правонарушителя (-ей) и передать его (их) прибывшему наряду полиции по акту",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85105,
                    "questionId": 7605,
                    "name": "Нажать кнопку экстренного вызова полиции, соблюдая бдительность и осторожность провести внешний осмотр помещения и лично убедиться в правильности полученного сигнала, подготовиться к встрече сотрудников полиции. Доложить оперативному дежурному частной охранной организации",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85106,
                    "questionId": 7605,
                    "name": "Позвонить оперативному дежурному частной охранной организации и попросить выслать мобильную группу",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7633,
            "name": "Что должен предпринять работник стационарного поста охраны в образовательной организации при обнаружении неисправности пожарной сигнализации?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85188,
                    "questionId": 7633,
                    "name": "Позвонить мастеру и сотруднику безопасности объекта образования",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85189,
                    "questionId": 7633,
                    "name": "Проверить помещения на наличие огнеопасных веществ и оснастить их дополнительными огнетушителями",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85190,
                    "questionId": 7633,
                    "name": "Уведомить начальника охраны, оперативного дежурного частной охранной организации, организацию по обслуживанию пожарной сигнализации",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7640,
            "name": "Что должен предпринять работник стационарного поста охраны в образовательной организации при обнаружении неисправности средств связи?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85209,
                    "questionId": 7640,
                    "name": "Позвонить мастеру",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85210,
                    "questionId": 7640,
                    "name": "Уведомить начальника охраны и оперативного дежурного частной охранной организации по альтернативному средству связи",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85211,
                    "questionId": 7640,
                    "name": "Уведомить руководство образовательной организации и попросить заменить неисправное средство связи",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7628,
            "name": "Что обязан знать работник стационарного поста охраны в образовательной организации при использовании охранной сигнализации?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85173,
                    "questionId": 7628,
                    "name": "Инструкцию использования охранной сигнализации и порядок действий при поступлении сигнала тревоги",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85174,
                    "questionId": 7628,
                    "name": "Инструкцию использования охранной сигнализации, все виды сигналов тревоги, соответствие сигналов тревоги охраняемым помещениям, а также точное расположение охраняемых помещений и кратчайшие маршруты движения к ним",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85175,
                    "questionId": 7628,
                    "name": "Номера телефонов лиц образовательной организации, ответственных за охраняемые помещения",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7627,
            "name": "Что главным образом обязан контролировать работник стационарного поста охраны в образовательной организации при использовании противогаза?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85170,
                    "questionId": 7627,
                    "name": "Время использования противогаза в загазованном (задымленном) помещении",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85171,
                    "questionId": 7627,
                    "name": "Запах вдыхаемого воздуха",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85172,
                    "questionId": 7627,
                    "name": "При использовании противогаза охранник не обязан что-либо контролировать",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7625,
            "name": "При взятии помещений под охрану сигнализацией работник стационарного поста охраны на объекте образования обязан проверить:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85164,
                    "questionId": 7625,
                    "name": "Датчик движения не имеет внешних повреждений, и он намеренно не зашторен (бумагой, тряпкой и иными предметами, не позволяющими датчику работать в штатном режиме); окна и форточки закрыты на защелки; помещение освобождено от людей и животных и заперто",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85165,
                    "questionId": 7625,
                    "name": "Исправность охранной сигнализации",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85166,
                    "questionId": 7625,
                    "name": "Срабатывает сигнализация при нарушении или нет",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7629,
            "name": "Кто обязан проверять исправность пожарной сигнализации на охраняемом объекте образования?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85176,
                    "questionId": 7629,
                    "name": "Пожарная сигнализация должна периодически проверяться оператором пульта пожарной охраны",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85177,
                    "questionId": 7629,
                    "name": "Пожарная сигнализация должна периодически проверяться работником стационарного поста охраны в образовательной организации, а результат проверки записываться им в специальный журнал",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85178,
                    "questionId": 7629,
                    "name": "Пожарная сигнализация должна периодически проверяться специалистом. В ходе проверки специалист должен уведомлять работника охраны образовательной организации и ответственного за пожарную безопасность объекта о том, какую зону он проверяет. Результат проверки записывается в специальный журнал",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7620,
            "name": "Какие технические средства охраны должен иметь работник стационарного поста охраны в образовательной организации непосредственно при себе?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85149,
                    "questionId": 7620,
                    "name": "Мобильный телефон - в кармане, кнопка экстренного вызова полиции (брелок) – на ремешке или на поясном ремне, рация - на поясном ремне (если охранников или постов охраны более, чем один)",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85150,
                    "questionId": 7620,
                    "name": "Технические средства, для которых имеется место в форменной одежде",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85151,
                    "questionId": 7620,
                    "name": "Технические средства, которые необходимы в данный момент",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7637,
            "name": "В здании образовательной организации произошло возгорание проводки и электрооборудования под высоким напряжением. Для локализации очага возгорания работнику стационарного поста охраны необходимо воспользоваться:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85200,
                    "questionId": 7637,
                    "name": "Возгорание проводки и электрооборудования под высоким напряжением можно локализовать только большим объемом воды",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85201,
                    "questionId": 7637,
                    "name": "Порошковым огнетушителем",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85202,
                    "questionId": 7637,
                    "name": "Углекислотным огнетушителем",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7624,
            "name": "Что обязан знать работник стационарного поста охраны в образовательной организации при использовании систем контроля и управления доступом (СКУД)?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85161,
                    "questionId": 7624,
                    "name": "Инструкцию использования системы контроля и управления доступом, места размещения блоков управления и точек электропитания системы контроля и управления доступом",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85162,
                    "questionId": 7624,
                    "name": "Инструкцию использования системы контроля и управления доступом, метод отключения системы контроля и управления доступом",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85163,
                    "questionId": 7624,
                    "name": "Инструкцию использования системы контроля и управления доступом, точное расположение точек доступа (турникеты, запасные выходы и служебные входы) и кратчайшие маршруты движения к ним",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7622,
            "name": "Охранная сигнализация на объекте образования необходима для:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85155,
                    "questionId": 7622,
                    "name": "Для контроля зон охраны с целью информирования оператора пульта охраны о попытке или факте проникновения нарушителя",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85156,
                    "questionId": 7622,
                    "name": "Для контроля зон охраны с целью информирования сотрудника охраны и оператора пульта охраны о попытке или факте проникновения нарушителя",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85157,
                    "questionId": 7622,
                    "name": "Контроля зон охраны с целью информирования сотрудника охраны о попытке или факте проникновения нарушителя",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7635,
            "name": "Правила ведения радиообмена включают в себя:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85194,
                    "questionId": 7635,
                    "name": "Использование кодов понятий, знание таблицы позывных (при их наличии)",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85195,
                    "questionId": 7635,
                    "name": "Использование позывных, знание таблицы позывных; использование кодов понятий и правил передачи информации",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85196,
                    "questionId": 7635,
                    "name": "Использование правил передачи информации и позывных",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7643,
            "name": "Какое техническое средство охраны допускается размещать в служебных кабинетах охраняемого объекта образования (директора, секретаря, канцелярии, бухгалтерии)?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85218,
                    "questionId": 7643,
                    "name": "Извещатель пожарной тревоги ручной",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85219,
                    "questionId": 7643,
                    "name": "Монитор и органы управления системы контроля и управления доступом",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85220,
                    "questionId": 7643,
                    "name": "Стационарная кнопка экстренного вызова полиции",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7621,
            "name": "Порядок действий работника стационарного поста охраны в образовательной организации при обнаружении неисправности камеры видеонаблюдения:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85152,
                    "questionId": 7621,
                    "name": "Позвонить мастеру",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85153,
                    "questionId": 7621,
                    "name": "Уведомить оперативного дежурного частной охранной организации и находиться в наиболее опасной зоне наблюдения",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85154,
                    "questionId": 7621,
                    "name": "Уведомить специалиста, обслуживающего компьютерное оборудование образовательной организации, службу техподдержки, оперативного дежурного частной охранной организации",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7642,
            "name": "Причинами неоднократного поступления ложных сигналов тревоги «задымление» могут быть:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85215,
                    "questionId": 7642,
                    "name": "Высокая запыленность зоны, появление в зоне пожарной охраны пара, намеренная провокация датчика сигаретным дымом",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85216,
                    "questionId": 7642,
                    "name": "Намеренное зашторивание датчика (бумагой, тряпкой и иными предметами, не позволяющими датчику работать в штатном режиме)",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85217,
                    "questionId": 7642,
                    "name": "Открытые окна и форточки",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7630,
            "name": "Для чего образовательные организации оснащаются системами контроля и управления доступом (СКУД)?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85179,
                    "questionId": 7630,
                    "name": "Для обеспечения санкционированного входа в зоны ограниченного доступа и выхода из них путем идентификации личности (карточка), а также предотвращения несанкционированного прохода в зоны ограниченного доступа объекта образования",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85180,
                    "questionId": 7630,
                    "name": "Для освобождения работника охраны от обязанности соблюдения пропускного режима на объекте образования",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85181,
                    "questionId": 7630,
                    "name": "Для учета посещения объекта образования",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7623,
            "name": "Порядок проверки исправности кнопки экстренного вызова полиции работником стационарного поста охраны в образовательной организации:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85158,
                    "questionId": 7623,
                    "name": "Нажать кнопку экстренного вызова полиции, дождаться прибытия наряда полиции, позвонить на пульт централизованного наблюдения и доложить о результатах проверки кнопки экстренного вызова полиции",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85159,
                    "questionId": 7623,
                    "name": "Позвонить на пульт централизованного наблюдения, назвать пультовой номер, представиться, нажать стационарную кнопку экстренного вызова полиции, получить подтверждение получения сигнала тревоги от оператора, нажать брелок кнопки экстренного вызова полиции, получить подтверждение получения сигнала тревоги от оператора, получить новый пароль, записать в журнал результат проверки всех кнопок экстренного вызова полиции",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85160,
                    "questionId": 7623,
                    "name": "Позвонить начальнику охраны, который обязан прибыть на объект образования и проверить кнопку экстренного вызова полиции",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7626,
            "name": "Что обязан проверять работник стационарного поста охраны в образовательной организации при использовании фонаря?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85167,
                    "questionId": 7626,
                    "name": "Дальность луча",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85168,
                    "questionId": 7626,
                    "name": "Охранник не обязан проверять фонарь",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85169,
                    "questionId": 7626,
                    "name": "Работоспособность и заряд батареи",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7632,
            "name": "При необходимости передать информацию через средство связи в надетом противогазе охраннику стационарного поста охраны следует:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85185,
                    "questionId": 7632,
                    "name": "Передача речевой информации в надетом противогазе не предусмотрена",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85186,
                    "questionId": 7632,
                    "name": "Поднести микрофон средства связи к переговорной мембране или к выпускному клапану противогаза и произнести фразу громко и четко, на сдержанном выдохе",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85187,
                    "questionId": 7632,
                    "name": "Сделать глубокий вдох в противогазе, закрыть глаза, быстро поднять маску противогаза, освободив рот, произнести нужную информацию и как можно быстрее вернуть противогаз в исходное положение",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7619,
            "name": "Что должен предпринять работник стационарного поста охраны в образовательной организации при обнаружении неисправности кнопки экстренного вызова полиции?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85146,
                    "questionId": 7619,
                    "name": "Позвонить мастеру",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85147,
                    "questionId": 7619,
                    "name": "Уведомить оператора пульта централизованного наблюдения, начальника охраны, оперативного дежурного частной охранной организации",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85148,
                    "questionId": 7619,
                    "name": "Уведомить руководителя объекта образования",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7638,
            "name": "Что обязан контролировать работник охраны при использовании средств связи?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85203,
                    "questionId": 7638,
                    "name": "Заряд батареи",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85204,
                    "questionId": 7638,
                    "name": "Качество связи",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85205,
                    "questionId": 7638,
                    "name": "Уровень сигнала",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7631,
            "name": "Что обязан знать работник стационарного поста охраны в образовательной организации при использовании системы видеонаблюдения?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85182,
                    "questionId": 7631,
                    "name": "Памятку по действиям работника охраны при работе с системой видеонаблюдения, соответствие номеров видеокамер зонам видеонаблюдения, кратчайшие маршруты движения к зонам видеонаблюдения",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85183,
                    "questionId": 7631,
                    "name": "Памятку по действиям работника охраны при работе с системой видеонаблюдения, технические характеристики каждой видеокамеры, соответствие номеров видеокамер зонам видеонаблюдения",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85184,
                    "questionId": 7631,
                    "name": "Памятку по действиям работника охраны при работе с системой видеонаблюдения, технические характеристики каждой видеокамеры, технические характеристики видеорегистратора",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7634,
            "name": "Работник стационарного поста охраны в образовательной организации обязан проверять работоспособность средств связи:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85191,
                    "questionId": 7634,
                    "name": "Не реже двух раз в сутки",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85192,
                    "questionId": 7634,
                    "name": "Не реже одного раза в сутки",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85193,
                    "questionId": 7634,
                    "name": "Частота проверок зависит от технических характеристик используемых средств связи",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7636,
            "name": "Назначение системы оповещения на объекте образования:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85197,
                    "questionId": 7636,
                    "name": "Озвучивание пожарной тревоги и объявлений",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85198,
                    "questionId": 7636,
                    "name": "Оперативное информирование людей о возникшем пожаре или иной нештатной ситуации (аварии, стихийном бедствии, нападении, террористическом акте) и координация их действий, в том числе, управление эвакуацией людей",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85199,
                    "questionId": 7636,
                    "name": "Оперативное информирование работника охраны о возникшей или приближающейся внештатной ситуации (аварии, пожаре, стихийном бедствии, нападении, террористическом акте)",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7639,
            "name": "Какую проверку огнетушителей должен выполнить работник по обеспечению охраны образовательных организаций, заступая на пост охраны?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85206,
                    "questionId": 7639,
                    "name": "Охранник не должен проверять огнетушители",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85207,
                    "questionId": 7639,
                    "name": "Проверить наличие огнетушителей",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85208,
                    "questionId": 7639,
                    "name": "Проверить наличие огнетушителей, их опломбирование и давление газа в баллонах огнетушителей",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7641,
            "name": "Что обязан знать работник стационарного поста охраны в образовательной организации при использовании металлодетектора?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85212,
                    "questionId": 7641,
                    "name": "Инструкцию по эксплуатации металлодетектора и его эксплуатационные характеристики",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85213,
                    "questionId": 7641,
                    "name": "Инструкцию эксплуатации металлодетектора",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85214,
                    "questionId": 7641,
                    "name": "Принцип действия металлодетектора",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7666,
            "name": "Психические расстройства и расстройства поведения, связанные с употреблением психоактивных веществ согласно требованиям законодательства РФ:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85287,
                    "questionId": 7666,
                    "name": "Не являются препятствием для исполнения обязанностей частного охранника и противопоказанием к владению оружием",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85288,
                    "questionId": 7666,
                    "name": "Являются препятствием для исполнения обязанностей частного охранника и противопоказанием к владению оружием",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85289,
                    "questionId": 7666,
                    "name": "Являются противопоказанием только к владению оружием",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7668,
            "name": "Если работник стационарного поста охраны в образовательной организации стал свидетелем конфликта третьих лиц, как необходимо правильно себя повести, чтобы не спровоцировать дальнейшее развитие конфликта?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85293,
                    "questionId": 7668,
                    "name": "Вмешаться, жестко пресечь конфликт в начале его развития, по возможности препроводить конфликтующих за пределы территории образовательной организации",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85294,
                    "questionId": 7668,
                    "name": "Лучшая линия поведения - не вмешиваться в конфликт, а наблюдать со стороны, но при его активном развитии поставить в известность руководителя образовательной организации",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85295,
                    "questionId": 7668,
                    "name": "Пытаясь локализовать конфликт, действовать уверенно, проявлять максимальное уважение ко всем участникам конфликта, не принимать ничьей стороны, не допускать в развитие конфликта новых участников и «зрителей», действовать по принципу: «Защити слабого, которому в этой ситуации угрожает опасность, предотврати столкновение»",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7660,
            "name": "Действия работника стационарного поста охраны при попытке агрессивно настроенных родителей обучающихся проникнуть на территорию образовательной организации:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85269,
                    "questionId": 7660,
                    "name": "Необходимо спокойным, уверенным голосом рассказать посетителям о необходимости предварительной договоренности, по их требованию дать возможность ознакомиться с удостоверением частного охранника, указать на таблицу с указанием о действии на территории образовательной организации внутриобъектового и пропускного режимов",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85270,
                    "questionId": 7660,
                    "name": "Прибегнуть к убеждению агрессивно настроенных посетителей в необходимости вести себя прилично и не общаться с ними до тех пор, пока они не успокоятся",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85271,
                    "questionId": 7660,
                    "name": "Следует доложить руководителю и дежурному администратору образовательной организации, так как общаться с агрессивными посетителями входит в их должностные обязанности",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7649,
            "name": "Как нужно использовать ручной металлодетектор с целью психологической профилактики проноса в школу запрещенных предметов?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85236,
                    "questionId": 7649,
                    "name": "Во время утреннего прохода детей в школу необходимо держать металлодетектор в руках и время от времени обозначать техническое обследование детей и подростков",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85237,
                    "questionId": 7649,
                    "name": "Лучше всего отводить к специальному столу каждого десятого школьника, требовать выложить на столик все металлические предметы и проводить техническое обследование карманов и сумок",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85238,
                    "questionId": 7649,
                    "name": "Родители пишут жалобы в Департамент образования о недопустимости проведения «обысков детей», в связи с чем лучше держать металлодетектор в ящике стола и показывать только проверяющим",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7653,
            "name": "Состояние профессионального здоровья работника стационарного поста охраны в образовательной организации лучше всего характеризуют:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85248,
                    "questionId": 7653,
                    "name": "Отсутствие показаний к прохождению лечения от алкогольной или наркотической зависимости",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85249,
                    "questionId": 7653,
                    "name": "Отсутствие показаний химико-токсикологического исследования на нахождение в организме проверяемого наркотических веществ и их метаболитов",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85250,
                    "questionId": 7653,
                    "name": "Стабильно благополучное физическое и психологическое состояние организма после дозированных нагрузок в течение рабочей смены, обеспечивающее высокую эффективность профессиональной деятельности",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7644,
            "name": "В чем заключается психологическая готовность охранника образовательной организации к выполнению своих должностных обязанностей?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85221,
                    "questionId": 7644,
                    "name": "В более глубоком познании своего внутреннего мира",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85222,
                    "questionId": 7644,
                    "name": "В понимании психологических особенностей своей работы, в способности мобилизовать свою психику, настроиться на целесообразные действия и организовать свое поведение в конкретной профессиональной ситуации",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85223,
                    "questionId": 7644,
                    "name": "В удовлетворении потребности в своевременной выплате заработной платы и возможности питаться в пищеблоке образовательной организации",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7651,
            "name": "Мерами для предотвращения развития конфликтов с посетителями, недовольными требованиями к обеспечению пропускного режима в общеобразовательной организации, являются:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85242,
                    "questionId": 7651,
                    "name": "Запереть дверь и не разговаривать",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85243,
                    "questionId": 7651,
                    "name": "Объяснить посетителю, что работник охраны предотвращает террористическую угрозу",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85244,
                    "questionId": 7651,
                    "name": "Показать имеющиеся предупредительные таблички и дать ознакомиться с утвержденным руководителем образовательной организации Положением (инструкцией) о внутриобъектовом и пропускном режиме",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7657,
            "name": "Под гиперактивностью у детей понимают:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85260,
                    "questionId": 7657,
                    "name": "Высокую физическую активность, обусловливающую трудности засыпания",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85261,
                    "questionId": 7657,
                    "name": "Повышенную потребность в игре",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85262,
                    "questionId": 7657,
                    "name": "Такое физическое и психологическое развитие ребёнка, при котором наблюдаются дефицит внимания, импульсивность, чрезмерно высокая двигательная активность и возбудимость",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7663,
            "name": "Как следует вести себя с детьми работнику охраны при осуществлении пропускного режима во время проведения ЕГЭ?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85278,
                    "questionId": 7663,
                    "name": "Говорить четко, громко, в приказном тоне, на возможные вопросы детей не отвечать; при появлении сигнала металлодетектора обязательно комментировать вслух свои дальнейшие действия",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85279,
                    "questionId": 7663,
                    "name": "Для придания ситуации осмотра особой важности выполнять свои прямые обязанности со строгим выражением лица, не проявляя никаких эмоций, стараться не разговаривать с детьми, не смотреть им в глаза",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85280,
                    "questionId": 7663,
                    "name": "Чтобы не усугублять и без того стрессовую для детей ситуацию, охранник должен проявлять по отношению к ним максимальную доброжелательность, обращаться вежливо, быть спокойным и внимательным к состоянию детей",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7665,
            "name": "Стадии профессионального выгорания работника охраны:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85284,
                    "questionId": 7665,
                    "name": "Напряжение, сопротивление, истощение",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85285,
                    "questionId": 7665,
                    "name": "Неудовлетворенность собой, чувство вины, истощение",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85286,
                    "questionId": 7665,
                    "name": "Тревога, страх, депрессия",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7646,
            "name": "К наиболее значимым причинам, провоцирующим профессиональное выгорание работников охраны образовательных организаций, относятся:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85227,
                    "questionId": 7646,
                    "name": "Проблемы личного характера, неблагоприятное физическое самочувствие, незнание своих должностных обязанностей",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85228,
                    "questionId": 7646,
                    "name": "Проблемы со здоровьем, высокий риск травматизма, отсутствие возможности выйти за пределы охраняемой территории поста",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85229,
                    "questionId": 7646,
                    "name": "Хроническая напряженная психоэмоциональная деятельность, плохая организация деятельности, высокая ответственность за исполняемые функции, разнообразный, психологически трудный контингент, с которым приходится взаимодействовать",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7645,
            "name": "В чем заключаются особенности образовательной среды и участников образовательного процесса, знание которых необходимо работнику охраны при планировании своих действий и руководстве своим поведением на посту охраны?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85224,
                    "questionId": 7645,
                    "name": "В возможностях осуществления удаленного контроля за каждым шагом работника охраны, что является постоянным раздражающим фактором",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85225,
                    "questionId": 7645,
                    "name": "В наличии угроз и рисков безопасности, как проникающих извне, так и выращенных внутри; в психологических (в том числе, возрастных) особенностях детей, а также специфике поведения педагогов и посетителей образовательной организации",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85226,
                    "questionId": 7645,
                    "name": "В предусмотренной договорными обязательствами замене работника охраны по первому требованию руководителя образовательной организации",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7664,
            "name": "В чем заключается выявление истинных намерений посетителя образовательной организации по вербальным признакам во время осуществления охранником образовательной организации пропускного режима?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85281,
                    "questionId": 7664,
                    "name": "В анализе тембра голоса и содержания ответов на поставленные вопросы",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85282,
                    "questionId": 7664,
                    "name": "В изучении возможного содержимого ручной клади",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85283,
                    "questionId": 7664,
                    "name": "В изучении позы тела при общении",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7650,
            "name": "Правильной линией поведения работника стационарного поста охраны в образовательной организации с проверяющими является:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85239,
                    "questionId": 7650,
                    "name": "Лучше всего молчать и дожидаться прибытия на объект начальника охраны",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85240,
                    "questionId": 7650,
                    "name": "Поблагодарить проверяющих за указание на выявленные в работе недостатки с заверением о немедленном их устранении",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85241,
                    "questionId": 7650,
                    "name": "Решительное возражение на неправильное (как считает работник охраны) замечание проверяющих, угрозы обжаловать попавшие в акт замечания о выявленных недостатках",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7654,
            "name": "Эффективной формой взаимодействия работника охраны с администрацией образовательной организации является:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85251,
                    "questionId": 7654,
                    "name": "Не спорить, не возражать, со всем соглашаться, чтобы не испортить отношения и не спровоцировать конфликт образовательной организации с частной охранной организацией",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85252,
                    "questionId": 7654,
                    "name": "Обеспечивая безопасность образовательной среды, необходимо искать слабые стороны и скрытые недостатки в работе администрации и своевременно указывать на них",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85253,
                    "questionId": 7654,
                    "name": "Сотрудничество, совместная деятельность по обеспечению безопасности образовательной среды и участников образовательного процесса",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7661,
            "name": "Ученицы младших классов привели к охраннику школы плачущую девочку и указали на своего одноклассника, который на школьном дворе бросил в лицо девочки снежок и повредил ей глаз. Охранник подошел к указанному мальчику и предложил следовать за ним к директору. Мальчик обругал охранника и бросился бежать. Как должен поступить работник охраны?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85272,
                    "questionId": 7661,
                    "name": "Догнать обучающегося, строго отругать за неподобающее поведение и силой отвести к директору школы",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85273,
                    "questionId": 7661,
                    "name": "Не проявляя лишних эмоций, оперативно доложить о происшествии дежурному администратору и написать докладную записку на имя руководителя образовательной организации, та как возраст ученика исключает его правовую ответственность",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85274,
                    "questionId": 7661,
                    "name": "Никак не реагировать, поскольку подобные обязанности не указаны в контракте (договоре) и должностной инструкции охранника",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7659,
            "name": "Поведенческие и эмоциональные особенности обучающихся и посетителей образовательной организации, которые должны привлекать повышенное внимание сотрудника стационарного поста охраны при осуществлении пропускного режима:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85266,
                    "questionId": 7659,
                    "name": "Высокая активность и темп речи, приподнятое настроение на фоне печального выражения глаз",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85267,
                    "questionId": 7659,
                    "name": "Грусть, печаль, плавность движений и речи, тихий голос, неуверенность в себе",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85268,
                    "questionId": 7659,
                    "name": "Нервозное (возбужденное) состояние, напряженность, страх, заторможенность, отрешенность от происходящего, неадекватное реагирование на обращение, признаки актуального наркотического опьянения, неестественность и наигранность в поведении",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7658,
            "name": "Очевидными физиологическими признаками, свидетельствующими о состоянии актуального наркотического опьянения, на которые должен обращать внимание работник охраны образовательной организации, являются:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85263,
                    "questionId": 7658,
                    "name": "Изменение размера зрачков, цвета кожных покровов, скорости речи, тембра голоса, координации движений, двигательной активности, настроения",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85264,
                    "questionId": 7658,
                    "name": "Нарушение общепринятых норм и правил поведения",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85265,
                    "questionId": 7658,
                    "name": "Проявления вербальной (словесной) и физической агрессии",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7667,
            "name": "Правильная линия поведения работника стационарного поста охраны в образовательной организации, если в школу пришел посетитель, настроенный не агрессивно, но проявляющий признаки неадекватного поведения, свидетельствующие о его психологическом неблагополучии (например, достает из сумки еду, задает охраннику странные вопросы, пытается обращаться к детям и другим посетителям):",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85290,
                    "questionId": 7667,
                    "name": "В жесткой форме пресечь любые попытки общения и лично препроводить посетителя за территорию образовательной организации",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85291,
                    "questionId": 7667,
                    "name": "Доложить о происходящем руководителю образовательной организации и вызвать сотрудников скорой помощи",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85292,
                    "questionId": 7667,
                    "name": "Не провоцируя посетителя на проявление агрессии, в доступной форме, спокойно, вежливо, но настойчиво убедить его покинуть территорию школы, ссылаясь на установленные правила",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7655,
            "name": "Знания из области возрастной психологии необходимы работникам охраны образовательных организаций в их профессиональной деятельности для того, чтобы:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85254,
                    "questionId": 7655,
                    "name": "Консультировать родителей и сотрудников образовательной организации по вопросам конструктивного общения с детьми",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85255,
                    "questionId": 7655,
                    "name": "Осуществлять пропускной режим",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85256,
                    "questionId": 7655,
                    "name": "Понимать специфику возрастных особенностей детей, грамотно строить взаимодействие с ними и прогнозировать возможные проблемы в поведении, имеющие отношение к нарушению безопасности образовательной организации",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7647,
            "name": "Для быстрого и эффективного восстановления физической работоспособности и снятия психологического напряжения, возникающих в процессе работы, работнику стационарного поста охраны в образовательной организации рекомендуется:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85230,
                    "questionId": 7647,
                    "name": "Воспользовавшись личным ноутбуком, посмотреть интересный фильм в бытовом помещении охраны",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85231,
                    "questionId": 7647,
                    "name": "После ухода дежурного администратора обзвонить всех друзей-земляков, у которых смена совпадает, и пообщаться на «отвлеченные» темы",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85232,
                    "questionId": 7647,
                    "name": "Применить известные ему краткосрочные приемы и техники психологической саморегуляции",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7656,
            "name": "Под девиантным (отклоняющимся) поведением детей и подростков понимают:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85257,
                    "questionId": 7656,
                    "name": "Нарушения поведения, не обусловленные психическими заболеваниями, заключающиеся в неадекватных, не соответствующих социокультурной норме, способах взаимодействия со сверстниками и взрослыми",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85258,
                    "questionId": 7656,
                    "name": "Психические заболевания аутического спектра",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85259,
                    "questionId": 7656,
                    "name": "Характерные и естественные особенности, свойственные данному возрасту",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7648,
            "name": "К основным направлениям психологической саморегуляции относятся:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85233,
                    "questionId": 7648,
                    "name": "Аутогенная тренировка, нервно-мышечная релаксация, работа с дыханием и биологически активными точками, медитация",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85234,
                    "questionId": 7648,
                    "name": "Работа с психологом",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85235,
                    "questionId": 7648,
                    "name": "Сон и утренняя зарядка",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7662,
            "name": "Что такое «профайлинг»?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85275,
                    "questionId": 7662,
                    "name": "Метод распознавания противоправных намерений посетителя по наиболее информативным частным признакам, его внешности, вербальному и невербальному поведению",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85276,
                    "questionId": 7662,
                    "name": "Проведение осмотра предметов, находящихся при посетителе и в его одежде с помощью металлодетектора",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85277,
                    "questionId": 7662,
                    "name": "Проверка документов и запись установочных данных посетителя в постовую документацию",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7652,
            "name": "В ситуации развивающегося на глазах сотрудника охраны образовательной организации конфликта учеников на межнациональной почве, его основная задача состоит в следующем:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85245,
                    "questionId": 7652,
                    "name": "Не придавать конфликту особого значения - дети есть дети, как поругались, так и помирятся",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85246,
                    "questionId": 7652,
                    "name": "Попытаться вникнуть в суть происходящего, понять причину конфликта и, по возможности, примирить конфликтующие стороны",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85247,
                    "questionId": 7652,
                    "name": "Проявляя максимальное уважение к национальным чувствам, не принимая ничьей стороны, не допустить вмешательство в развитие конфликта «групп поддержки» (родственников, земляков и т.д.). Доложить дежурному администратору, а в случае попытки прохода в образовательную организацию посторонних - немедленно вызвать помощь",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7683,
            "name": "Какие действия должен предпринять работника стационарного поста охраны в образовательной организации при обнаружении на школьном дворе группы курящих обучающихся?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85338,
                    "questionId": 7683,
                    "name": "Позвонить оперативному дежурному частной охранной организации и попросить выслать мобильную группу",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85339,
                    "questionId": 7683,
                    "name": "Предложить школьникам прекратить курение, а при неподчинении активировать кнопку экстренного вызова полиции",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85340,
                    "questionId": 7683,
                    "name": "Ссылаясь на законодательство о запрете курения на территории образовательной организации, предложить школьникам прекратить курение; о факте курения на территории школы сообщить руководству образовательной организации для принятия мер к нарушителям",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7686,
            "name": "Какие действия должен предпринять работник стационарного поста охраны в образовательной организации при попытке обучающегося старших классов покинуть здание школы в часы образовательного процесса?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85347,
                    "questionId": 7686,
                    "name": "Задержать обучающегося, вызвать дежурного администратора и передать ему школьника для разбирательства и принятия решения",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85348,
                    "questionId": 7686,
                    "name": "Не разрешить обучающемуся выйти из здания образовательной организации в неположенное время, доложить оперативному дежурному и сделать запись в журнале",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85349,
                    "questionId": 7686,
                    "name": "Позвонить оперативному дежурному частной охранной организации и попросить выслать мобильную группу",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7693,
            "name": "Наиболее часто встречающиеся формы девиантного поведения детей и подростков",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85368,
                    "questionId": 7693,
                    "name": "Бродяжничество, попрошайничество, употребление спиртных напитков, тунеядство, нежелание учиться, употребление наркотиков, приводы в полицию, суицидальное поведение, воровство, вандализм, участие в криминальных группах, жестокость, курение, раннее начало половой жизни, повышенная агрессия, хулиганство, токсикомания, гемблинг",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85369,
                    "questionId": 7693,
                    "name": "Олигофрения, кретинизм, паранойя, шизофрения, энурез, имбецилизм, идиотия",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85370,
                    "questionId": 7693,
                    "name": "Плохая успеваемость, быстрая утомляемость, низкая физическая активность, частые простудные заболевания, застенчивость",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7687,
            "name": "Первоочередные действия охранника стационарного поста при обнаружении попытки работника ремонтно-строительной бригады вынести из здания образовательной организации ноутбук:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85350,
                    "questionId": 7687,
                    "name": "Доложить начальнику охраны, позвонить оперативному дежурному частной охранной организации и попросить выслать мобильную группу",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85351,
                    "questionId": 7687,
                    "name": "Задержать нарушителя, вызвать дежурного администратора и передать ему работника строительной бригады с ноутбуком для дальнейшего разбирательства и принятия мер",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85352,
                    "questionId": 7687,
                    "name": "Нажать кнопку экстренного вызова полиции, задержать нарушителя и передать его прибывшему наряду полиции по акту. Доложить о происшествии оперативному дежурному и начальнику охраны, сделать запись в журнале",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7672,
            "name": "Кому обучающиеся передают запрещенные к проносу предметы, выявленные работником охраны образовательной организации при осуществлении пропускного режима во время проведения ЕГЭ?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85305,
                    "questionId": 7672,
                    "name": "Начальнику охраны (объекта, участка)",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85306,
                    "questionId": 7672,
                    "name": "Представителю образовательной организации, сопровождающему группу",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85307,
                    "questionId": 7672,
                    "name": "Присутствующему во время проведения комплексных контрольно-досмотровых мероприятий работнику полиции",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7675,
            "name": "Согласно порядку действий сотрудника частной охранной организации при возникновении обстоятельств, требующих временного оставления поста, работник стационарного поста охраны в образовательной организации в случае необходимости оставить пост на срок до 30 минут обязан:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85314,
                    "questionId": 7675,
                    "name": "Обратиться к дежурному администратору образовательной организации с просьбой о подмене",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85315,
                    "questionId": 7675,
                    "name": "Уведомить начальника охраны и оперативного дежурного частной охранной организации, которые поставят в известность дежурного администратора образовательной организации",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85316,
                    "questionId": 7675,
                    "name": "Уведомить оперативного дежурного частной охранной организации и ждать подмены из числа сотрудников мобильной группы частной охранной организации",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7678,
            "name": "При проходе через стационарный металлодетектор («рамку») обучающегося, идущего на ГИА-11, появляется звуковой сигнал. Что необходимо предпринять работнику охраны образовательной организации?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85323,
                    "questionId": 7678,
                    "name": "Не пропуская обучающегося через контрольно-пропускной пункт, предупредить его о невозможности пройти в пункт приема экзамена при реагирующем звуковым сигналом стационарном металлодетекторе. Подозвать сопровождающее лицо, попросить его вместе с обучающимся отойти за условную линию",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85324,
                    "questionId": 7678,
                    "name": "Подозвать сотрудника полиции и предложить произвести личный досмотр обучающегося с целью изъятия предметов, запрещенных к проносу на пункт приема экзамена",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85325,
                    "questionId": 7678,
                    "name": "Предложить обучающемуся сдать металлические предметы и повторно пройти через «рамку». В случае отказа информировать руководителя пункта приема экзамена. При повторном срабатывании «рамки» необходимо пропустить обучающегося на пункт приема экзамена, но напомнить, что при обнаружении у него запрещенных к проносу предметов он будет удален с экзамена. При этом сотрудник охраны обязан занести сведения об участнике ГИА-11 в учетную ведомость участников ГИА-11, на которых повторно сработал металлодетектор, и которые были переданы лицу, ответственному за пункт приема экзамена",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7671,
            "name": "Кому обучающиеся передают запрещенные к проносу предметы, выявленные работником охраны образовательной организации при осуществлении пропускного режима во время проведения ЕГЭ?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85302,
                    "questionId": 7671,
                    "name": "Начальнику охраны (объекта, участка)",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85303,
                    "questionId": 7671,
                    "name": "Представителю образовательной организации, сопровождающему группу",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85304,
                    "questionId": 7671,
                    "name": "Присутствующему во время проведения комплексных контрольно-досмотровых мероприятий работнику полиции",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7689,
            "name": "Последовательность действий работника стационарного поста охраны при прибытии в образовательную организацию лица с правом беспрепятственного прохода для проведения проверки:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85356,
                    "questionId": 7689,
                    "name": "Представиться, доложить об обстановке, ничего не спрашивая, пропустить в здание образовательной организации",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85357,
                    "questionId": 7689,
                    "name": "Проверить у прибывшего лица документы, сличить фотографию, затем беспрепятственно пропустить в здание образовательной организации",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85358,
                    "questionId": 7689,
                    "name": "Проверить у прибывшего лица документы, сличить фотографию, затем представиться и беспрепятственно пропустить в образовательную организацию",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7676,
            "name": "При осуществлении пропускного режима с использованием информационной системы «Проход и питание» работник стационарного поста охраны в образовательной организации не обязан:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85317,
                    "questionId": 7676,
                    "name": "Выдавать временные карты посетителям путем занесения персональных данных в информационную систему «Проход и питание»",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85318,
                    "questionId": 7676,
                    "name": "Предоставлять памятку по работе информационной системы «Проход и питание», разработанную администрацией образовательной организации, родителям обучающихся (воспитанников)",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85319,
                    "questionId": 7676,
                    "name": "Сличать лица посетителей с изображением на мониторе",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7680,
            "name": "При попытке представителей родительской общественности пройти в помещение пищеблока образовательной организации с целью проверки меню, работнику стационарного поста охраны необходимо:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85329,
                    "questionId": 7680,
                    "name": "Вызвать дежурного администратора и доложить о намерениях представителей родительской общественности",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85330,
                    "questionId": 7680,
                    "name": "Запереть входную дверь и лично проводить представителей родительской общественности в пищеблок",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85331,
                    "questionId": 7680,
                    "name": "Связаться с сотрудниками пищеблока по телефону, проинформировать и дождаться их решения о допуске родительской общественности",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7682,
            "name": "Кого работнику стационарного поста охраны в образовательной организации разрешено впускать в здание в ночное время?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85335,
                    "questionId": 7682,
                    "name": "Никого",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85336,
                    "questionId": 7682,
                    "name": "Сотрудников образовательной организации",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85337,
                    "questionId": 7682,
                    "name": "Только лиц, определенных приказом руководителя образовательной организации о допуске на объект в нерабочее время, выходные и праздничные дни",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7669,
            "name": "Какой вариант ответа более полно отражает необходимые действия в ходе осмотра помещений и территории образовательной организации при заступлении на пост в целях обнаружения признаков наличия возможной террористической угрозы?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85296,
                    "questionId": 7669,
                    "name": "Осмотр коммуникаций и критических элементов объекта – образовательной организации (или части объекта, входящей в зону ответственности охранника), технических полостей, предметов и оборудования в помещениях с уточнением наличия угрожающих признаков, принадлежности и назначения обнаруживаемых предметов",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85297,
                    "questionId": 7669,
                    "name": "Осмотр предметов и оборудования в помещениях образовательной организации с уточнением наличия угрожающих признаков, принадлежности и назначения обнаруживаемых предметов",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85298,
                    "questionId": 7669,
                    "name": "Осмотр прилегающей к образовательной организации территории и периметра образовательной организации (либо их участков, непосредственно прилегающих к зоне ответственности охранника), осмотр коммуникаций и критических элементов объекта – образовательной организации, технических полостей, предметов и оборудования в помещениях с уточнением наличия угрожающих признаков, принадлежности и назначения обнаруживаемых предметов",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7684,
            "name": "Последовательность действий работника стационарного поста охраны в образовательной организации при обнаружении на территории охраняемого объекта образования обучающегося в состоянии алкогольного опьянения:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85341,
                    "questionId": 7684,
                    "name": "Вызвать дежурного администратора образовательной организации и передать ему подростка «из рук в руки» для дальнейшего разбирательства и принятия мер к нарушителю",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85342,
                    "questionId": 7684,
                    "name": "Вызвать родителей и передать им подростка «из рук в руки»",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85343,
                    "questionId": 7684,
                    "name": "Сообщить оперативному дежурному частной охранной организации и в дальнейшем действовать по его указанию",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7681,
            "name": "В образовательную организацию пришел сотрудник комбината питания, которому необходимо пройти на пищеблок. Охранник стационарного поста охраны не находит данного посетителя в утвержденном руководителем образовательной организации списке сотрудников комбината питания. Как в этом случае необходимо поступить работнику охраны?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85332,
                    "questionId": 7681,
                    "name": "Оповестить дежурного администратора образовательной организации, оформить разовый пропуск в установленном порядке и пропустить посетителя для посещения пищеблока строго в сопровождении сотрудника образовательной организации",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85333,
                    "questionId": 7681,
                    "name": "Отказать в допуске на пищеблок на основании отсутствия данного посетителя в списке сотрудников комбината питания, утвержденном руководителем образовательной организации",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85334,
                    "questionId": 7681,
                    "name": "Пропустить посетителя на основании предъявления паспорта и служебного удостоверения",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7670,
            "name": "Какой вариант состава и периодичности производства доклада дежурному частной охранной организации о наличии либо отсутствии признаков террористической угрозы в образовательной организации в наибольшей степени соответствует задаче обеспечения безопасности?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85299,
                    "questionId": 7670,
                    "name": "Доклад о наличии либо отсутствии признаков террористической угрозы в образовательной организации производится при заступлении на пост и при сдаче поста; включает в себя информацию о том, что осмотрено непосредственно место расположения охранника (центр поста), признаки наличия возможной террористической угрозы (обнаружены или не обнаружены)",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85300,
                    "questionId": 7670,
                    "name": "Доклад о наличии либо отсутствии признаков террористической угрозы в образовательной организации производится при заступлении на пост, при сдаче поста, а также в течение смены (с установленной руководством периодичностью); включает в себя информацию о том, что осмотрена зона ответственности охранника и участки территории, непосредственно прилегающие к образовательной организации, в том числе критические элементы, технические полости, коммуникации, предметы и оборудование в помещениях,  в результате чего посторонние предметы, предметы, принадлежность и назначение которых неизвестны, посторонние лица, лица с подозрительным или нестандартным поведением, а также иные признаки наличия возможной террористической угрозы (обнаружены или не обнаружены)",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85301,
                    "questionId": 7670,
                    "name": "Доклад о наличии либо отсутствии признаков террористической угрозы в образовательной организации производится только при заступлении на пост; включает в себя информацию о том, что осмотрено непосредственно место расположения охранника (центр поста), признаки наличия возможной террористической угрозы (обнаружены или не обнаружены)",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7685,
            "name": "Последовательность действий работника стационарного поста охраны при появлении подозрения о попытке проноса в образовательную организацию оружия:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85344,
                    "questionId": 7685,
                    "name": "В категоричной форме отказать посетителю в проходе в образовательную организацию, сообщив ему о своих подозрениях",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85345,
                    "questionId": 7685,
                    "name": "Не пропуская посетителя, позвонить оперативному дежурному частной охранной организации и попросить выслать мобильную группу",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85346,
                    "questionId": 7685,
                    "name": "Незаметно нажать кнопку экстренного вызова полиции, по возможности, под любым предлогом, не допустить прохода посетителя до прибытия наряда полиции",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7677,
            "name": "Кому подчиняется работник стационарного поста охраны в образовательной организации в случае прибытия на охраняемый объект служб экстренного реагирования по причине возникновения чрезвычайной ситуации?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85320,
                    "questionId": 7677,
                    "name": "Начальнику штаба по ликвидации последствий чрезвычайной ситуации на охраняемом объекте",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85321,
                    "questionId": 7677,
                    "name": "Несмотря на происходящие на объекте события вопрос подчиненности охранника остается прежним - только начальнику охраны и руководителю частной охранной организации",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85322,
                    "questionId": 7677,
                    "name": "Руководителю образовательной организации и (или) представителям государственного органа, уполномоченного в сфере образования",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7692,
            "name": "На объектах образования с круглосуточным пребыванием обучающихся в обязательном порядке выполняются следующие требования: ",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85365,
                    "questionId": 7692,
                    "name": "В ночное время все эвакуационные выходы должны быть открыты; громкость звуковой сигнализации в ночное время должна быть увеличена на 20 дБ; табло световой сигнализации должны иметь ярко-белый цвет",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85366,
                    "questionId": 7692,
                    "name": "Должно быть обеспечено своевременное получение доступной и качественной информации о пожаре, включающей дублированную световую, звуковую и визуальную сигнализацию, подключенную к системе оповещения обучающихся и персонала о пожаре; световая, звуковая и визуальная информирующая сигнализация должна быть предусмотрена в помещениях, посещаемых данной категории лиц, а также у каждого эвакуационного, аварийного выхода и на путях эвакуации; световые сигналы в виде светящихся знаков должны включаться одновременно со звуковыми сигналами; частота мерцания световых сигналов должна быть не выше 5 Гц; визуальная информация должна располагаться на контрастном фоне с размерами знаков, соответствующими расстоянию рассмотрения",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85367,
                    "questionId": 7692,
                    "name": "Должно быть предусмотрено наличие средств пожаротушения с коэффициентом 1,5 от предусмотренной нормы",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7679,
            "name": "В ходе осуществления пропускного режима образовательной организации работник стационарного поста охраны заметил у одного из входящих в здание старшеклассников торчащий из-под куртки ствол ружья. При попытке остановить школьника последний направил оружие на охранника и потребовал не мешать ему. Как должен поступить охранник?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85326,
                    "questionId": 7679,
                    "name": "Включить громкоговорящую связь и предупредить всю школу о возникновении террористической угрозы",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85327,
                    "questionId": 7679,
                    "name": "Нейтрализовать нарушителя, отобрав у него оружие, в случае необходимости применить физическую силу",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85328,
                    "questionId": 7679,
                    "name": "Пропустить школьника на объект, не вступать с ним в переговоры и не смотреть ему в глаза. Незамедлительно активировать кнопку экстренного вызова полиции, проинформировать руководство образовательной организации и оперативного дежурного частной охранной организации. Обязательно предупредить прибывших сотрудников полиции об оружии, указать направление движения и приметы нарушителя",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7674,
            "name": "В целях соблюдения требований пожарной безопасности помещения охраны образовательных организаций разрешается оснащать бытовыми приборами согласно перечню:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85311,
                    "questionId": 7674,
                    "name": "Микроволновая печь, электрический чайник, холодильник, мультиварка",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85312,
                    "questionId": 7674,
                    "name": "Мультиварка, холодильник, газовая плитка, электрический чайник",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85313,
                    "questionId": 7674,
                    "name": "Холодильник, электрическая плитка, микроволновая печь, кипятильник, утюг",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7673,
            "name": "Кто может дать указание работнику стационарного поста охраны в образовательной организации оставить на ночлег водителя автомобиля, доставившего на объект строительные материалы, если автомобиль некому разгрузить?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85308,
                    "questionId": 7673,
                    "name": "Никто не обладает указанными полномочиями",
                    "isAnswer": true,
                    "selected": false
                },
                {
                    "id": 85309,
                    "questionId": 7673,
                    "name": "Оперативный дежурный частной охранной организации",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85310,
                    "questionId": 7673,
                    "name": "Руководитель образовательной организации, а в его отсутствии - ответственный за безопасность",
                    "isAnswer": false,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7690,
            "name": "При проведении в летний период ремонтно-строительных работ в образовательной организации где должны спать работники ремонтных и строительных бригад?",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85359,
                    "questionId": 7690,
                    "name": "В помещении образовательной организации, которое указано в соответствующем приказе руководителя образовательной организации",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85360,
                    "questionId": 7690,
                    "name": "В строительных вагончиках на территории образовательной организации",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85361,
                    "questionId": 7690,
                    "name": "За пределами территории образовательной организации",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7688,
            "name": "Действия работника стационарного поста охраны в дошкольной образовательной организации при обнаружении попытки незнакомого гражданина увести с прогулки по территории образовательной организации одного из детей в период кратковременной отлучки воспитателя:",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85353,
                    "questionId": 7688,
                    "name": "Нажать кнопку экстренного вызова полиции задержать нарушителя и передать его прибывшему наряду полиции по акту",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85354,
                    "questionId": 7688,
                    "name": "Не разрешить выход с территории образовательной организации гражданина с ребенком",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85355,
                    "questionId": 7688,
                    "name": "Немедленно пресечь попытку увода с территории детского сада ребенка, вызвать по телефону дежурного администратора образовательной организации и передать ей воспитанницу, доложить о случившемся оперативному дежурному частной охранной организации и в дальнейшем действовать по его указанию",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        },
        {
            "id": 7691,
            "name": "Последовательность действий работника стационарного поста охраны при возникновении пожара в образовательной организации",
            "questionTypeId": 1,
            "options": [
                {
                    "id": 85362,
                    "questionId": 7691,
                    "name": "Организовать общее руковод­ство по тушению пожара до прибытия подразде­лений пожарной охраны, обеспечив при этом со­блюдение мер безопасности; в случае угрозы жизни людей организовать их эвакуацию; проверить и включить системы противопо­жарной защиты (оповещения, пожаротушения, дымоудаления); при необходимости отключить электро­энергию и другие системы (вентиляции, лифты); прекратить все виды деятельности в здании; эвакуировать всех учащихся и персонал; прибыть к месту пожара и сообщить о его возникновении в пожарную охрану; встретить подразделения пожарной охраны и показать им кратчайший путь до очага пожара, местонахождение пожарных гидрантов, водо­емов, планировку здания, пути эвакуации и иные необходимые сведения",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85363,
                    "questionId": 7691,
                    "name": "При необходимости отключить электро­энергию и другие системы (вентиляции, лифты); прибыть к месту пожара и сообщить о его возникновении в пожарную охрану; встретить подразделения пожарной охраны и показать им кратчайший путь до очага пожара, местонахождение пожарных гидрантов, водо­емов, планировку здания, пути эвакуации и иные необходимые сведения; организовать общее руковод­ство по тушению пожара до прибытия подразде­лений пожарной охраны, обеспечив при этом со­блюдение мер безопасности; проверить и включить системы противопо­жарной защиты (оповещения, пожаротушения, дымоудаления); прекратить все виды деятельности в здании; эвакуировать всех учащихся и персонал",
                    "isAnswer": false,
                    "selected": false
                },
                {
                    "id": 85364,
                    "questionId": 7691,
                    "name": "Прибыть к месту пожара и сообщить о его возникновении в пожарную охрану; в случае угрозы жизни людей организовать их эвакуацию; проверить и включить системы противопо­жарной защиты (оповещения, пожаротушения, дымоудаления); при необходимости отключить электро­энергию и другие системы (вентиляции, лифты); прекратить все виды деятельности в здании; эвакуировать всех учащихся и персонал; организовать общее руковод­ство по тушению пожара до прибытия подразде­лений пожарной охраны, обеспечив при этом со­блюдение мер безопасности; встретить подразделения пожарной охраны и показать им кратчайший путь до очага пожара, местонахождение пожарных гидрантов, водо­емов, планировку здания, пути эвакуации и иные необходимые сведения",
                    "isAnswer": true,
                    "selected": false
                }
            ],
            "questionType": {
                "id": 1,
                "name": "Multiple Choice",
                "isActive": true
            },
            "autoMove": true
        }
    ],
    "periodicnypename": "--",
    "guardcatname": "--"
}