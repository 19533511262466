import { QUIZ_GRADE_SPECIAL } from './grade-special';
import { QUIZ_GRADE4 } from './grade4';
import { QUIZ_GRADE6 } from './grade6';
import { QUIZ_GRADE4QE } from './grade4qe';
import { QUIZ_GRADE6QE } from './grade6qe';
import { GRO } from './gro';
import { GROMED } from './gromed';
import { TWOLEVEL } from './twolevel';
import { QUIZ_GRADE_TB } from './gradetb';
export const GRADE_MAP = {
  grade4: { ...QUIZ_GRADE4 },
  grade6: { ...QUIZ_GRADE6 },
  grade4qe: { ...QUIZ_GRADE4QE },
  grade6qe: { ...QUIZ_GRADE6QE },
  special: { ...QUIZ_GRADE_SPECIAL },
  gromed: { ...GROMED },
  gro: { ...GRO },
  twolevel: { ...TWOLEVEL },
  gradetb: { ...QUIZ_GRADE_TB}
};